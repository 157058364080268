.authorsWrap {
  display: flex;
  /* display: none; */
  /* align-items: last baseline; */
  justify-content: auto;
  margin: 10px 10px 0px 0px;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
}
.filters {
  width: 220px;
  /* height: 300px; */
  padding: 20px 20px;
  border-radius: 4px;
  margin-top: 50vh;
  margin-left: 10px;
  background: #f4f6f7;

  /* box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}
.filters li {
  margin-bottom: 10px;
  list-style: none;
}

.filters2 {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -9999999;
  background-color: rgba(0, 0, 0, 0.4);
}
.filterHeading {
  text-align: center;
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 700;
  display: block;
}
/* select {
    width: 100%;
    height: 32px;
    background: #fff;
    text-transform: capitalize;
    font-size: 13px;
  } */
fieldset {
  padding: 0;
  border: 0;
}
fieldset label {
  margin-left: 8px;
}
.applyFilters {
  background: #1976d2;
  color: #fff;
  margin-top: 10px;
  padding: 8px;
  width: 100%;
  /* font-size: 15px; */
  border-radius: 4px;
  font-weight: 700;
  border: 0;
}
/* .searchWrap {
    display: flex;
    justify-content: flex-end;
    margin: 0 50px;
  }
  .searchWrap button {
    max-width: 100px;
  }
  .searchWrap input {
    font-size: 15px;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 10px;
    margin-right: 21px;
    width: 300px;
  } */

.majorDiv {
  border-bottom: 1px solid #ccc;
  margin-bottom: 14px;
  padding-bottom: 4px;
}
.filtersDiv {
  margin-left: 21px;
}
.filterLabel {
  margin-left: 8px;
}

.AllDiv {
  display: inline-flex;
  margin: 0;
  padding: 0;
}
.clear {
  color: rgb(45, 146, 241);
  margin: 0;
  padding: 0;
  margin-left: 35px;
  cursor: pointer;
}

/* .checkBox input[type="checkbox"] {
  visibility: hidden;
  position: absolute;
}
.checkBox input[type="checkbox"] + label:before {
  height: 12px;
  width: 12px;
  margin-right: 5px;

  content: " ";
  display: inline-block;
  background-color: #f5f5f6;

  left: -20px;
  top: 1px;
  border: 1px solid #777;
}
.checkBox input[type="checkbox"]:checked + label:before {
  content: "";
  width: 9px;
  height: 9px;
  background-color: rgb(45, 146, 241);
  left: -18px;
  top: 3px;
  border: 1px solid #c9cacb;
}
.checkBox input[type="checkbox"] + label:before {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%;
}
.checkBox input[type="checkbox"]:checked + label:after {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%;
} */
/* @media (max-width: 991px) {
  .authorsWrap {
    display: none;
  }
} */

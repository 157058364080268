.bankListContainer {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* font-family: var(--font); */
  font-family: "Poppins", sans-serif;
  padding: 0 1.5rem;
  /* margin-top: 10px; */
  padding-bottom: 50px;
  /* border: 3px solid #2a0dd2; */
}
.bankListContainer {
  width: 100%; /* Adjust width as needed */
  /* margin: 20px auto; */
  /* border: 3px solid #2a0dd2; */
  /* border-bottom: 1px solid #c9c8cf; */
  /* border-radius: 5px; */
  /* overflow-x: scroll; */
}

.bankListWrapper {
  /* border: 2px solid black; */
  margin-top: 10px !important;
}
.searchContianer {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* border: 1px solid slateblue; */
}

.mobileSearchContainer {
  /* border: 1px solid slateblue; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.tableWraper {
  width: 60%;
  /* display: block; */
  /* border-top: 1px solid #c9c8cf; */
  /* border-bottom: 1px solid #c9c8cf; */
  /* border: 3px solid #9a8fdc; */
  /* border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  /* padding: 10px; */
}

.tableDiv {
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  background-color: #ffffff; */
}
/* Table styling */
.tableDiv table {
  /* margin-top: 10px; */
  /* margin-bottom: 16px; */
  /* margin: 1em 0; */
  overflow: auto;
  /* overflow-y: hidden;
  overflow-x: auto; */
  width: 100%;
  /* border-collapse: collapse; */
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  /* box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  /* border: 3px solid #1c4392; */
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}

th,
td {
  padding: 16px;
  padding-left: 10px;
  padding-right: 10px;
  /* margin-left: 10px; */
  text-align: left;
  /* border-bottom: 1px solid #ddd; */
  font-family: "Poppins", sans-serif;
  /* border: 1px solid black; */
}

th {
  background-color: #e0e4e9;
  font-weight: 700;
  /* text-transform: uppercase; */
  /* font-size: 0.75rem; */
  /* letter-spacing: 0.1em; */
  font-family: Inter, sans-serif;
}
td {
  color: #2d3748;
  font-family: Inter, sans-serif;
}

tbody td {
  /* padding-left: 15px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 25px;
  /* color: #1a60da; */
  /* border: 1px solid black; */
}
.interestRateData {
  padding-left: 2px;
}
tbody tr:hover {
  background-color: #f4f6f7;
}
.bankName {
  /* width: 28%; */
  color: #2d3748;
  padding-left: 10px;
  padding-right: 0px;
  /* border: 2px solid salmon; */
}
.bankNameCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border: 2px solid saddlebrown; */
  /* width: 100px; */
}

th:hover {
  background-color: #e5e5e5;
}
thead {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  vertical-align: top;
  font-size: 16px;
  border-radius: 4px;
  color: #4a5568;
  cursor: pointer;
}

tbody {
  color: #2d3748;
}
.bankName a {
  /* color: #2d3748; */
}

thead th {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  padding: 18px;
  padding-left: 25px;
  padding-right: 0px;
  /* border: 1px solid black; */
}

.twTableDomain:hover {
  color: #195afe;
}

.appFaviconSM {
  width: 30px;
  height: 30px;
}

.appFavicon {
  display: flex;
  /* border: 2px solid salmon; */
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 5px;
  background: #fff;
  box-shadow: 4px 5px 16px rgba(64, 68, 77, 0.12);
  border-radius: 8px;
}

.twTableDomain {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* border: 2px solid blue; */
}

.arrow {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.sortDiv {
  /* display: inline; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filterButton {
  display: none;
  width: 87px;
  height: 2.4rem;
  /* margin: 10px;
  padding: 3px 8px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-right: 2px;
  border: 1px solid #ddd;
  border-radius: 3px; */
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
  /* font-size: 14px;
  line-height: 1.25;
  font-weight: 600;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  align-items: center;
}

.filterParentContainer {
  /* background-color: red; */
  margin: 0;
  padding: 0;
  margin-right: 15px;
}
.filterButtonContainer {
  display: flex;
  /* margin-right: 12px */
  /* padding-bottom: 23px; */
}

.filterHeading {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .updateMessageContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
} */
.top_controls__date {
  display: flex;
  /* width: 85%; */
  align-items: center;
  justify-content: center;
  font-size: 0.95rem;
  line-height: 16px;
  color: #8995a1;

  padding: 0;
  margin: 0;
}
.updateMsg0 {
  /* border: 3px solid salmon; */
}
.updateMsg {
  width: 100%;
  /* border: 3px solid salmon; */
  margin-bottom: 3px;

  padding: auto;
}
.bankHeading {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.interestRate {
  padding-left: 0px;
}
.rank {
  padding-right: 0px;
}
.bankDetails2 {
  /* color: #00b386; */
  color: #1a73e8;
  font-size: 14px;
}
.features {
  margin-top: 30px;
}
.featuresList {
  margin: 20px 0;
}
.featuresList ul li {
  margin: 10px 0;
  /* font-size: 14px; */
  font-size: 0.95rem;
  line-height: 17px;
  color: #8995a1;
  /* line-height: 1.3rem; */
}

.card {
  width: 100%;
  /* max-width: 590px; */
  height: 44px;
  /* height: 80px; */
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  /* background-color: red; */
  /* backdrop-filter: blur(10px);
  transition: 0.5s ease-in-out; */
  margin-left: -23px;
  /* border: 2px solid saddlebrown; */
}

.img {
  width: 44px;
  height: 44px;
  margin-left: 10px;
  border-radius: 4px;
  background: linear-gradient(#ffff, #ffff);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e6e5ee;
  /* background-color: #fff; */
}

.textBox {
  width: calc(100% - 90px);
  margin-left: 10px;
  margin-top: 3px;
  color: black;
}

.textContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 0;
  padding: 0; */
  /* border: 1px solid brown; */
}

.textContent:hover p {
  color: #195afe;
}
.h1 {
  font-size: 17px;
  /* font-weight: bold; */
  color: #2d3748;

  /* font-family: "Poppins" sans-serif; */
  /* margin: 0; */
}
.bankSearchMobile {
  /* margin-left: 10px; */
  margin-bottom: 10px;

  width: 100%;
  max-width: 400px;
}
.tableHeaderElements {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-right: auto;
  margin-bottom: 10px;
  /* border: 1px solid red; */
  /* width: 100%; */
}
@media (min-width: 1800px) {
  /* .tableWraper {
    width: 100%;
    border: 2px solid red;
  } */
  .bankListWrapper {
    margin: auto;
    padding: auto;
    /* width: 65%; */
    max-width: 1734px;
  }
  /* .bankListContainer {
    width: 100%;
    border: 2px solid black;
  } */
}
@media (max-width: 1240px) {
  .tableWraper {
    width: 70%;
  }
}
@media (max-width: 1230px) {
  .tableWraper table {
    /* width: 100%; */
    /* margin-right: 20px;     */
    /* display: block; */
    /* overflow: auto; */
    /* box-sizing: border-box; */
    /* border: 2px solid black; */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 5px;
  }
  .tableDiv {
    display: block;
    overflow: auto;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 5px;
  }
  .responsiveTable thead {
    /* border: 2px solid black; */
  }
  .bankListContainer {
    /* margin: 0px 15px; */
    /* margin-right: 20px;     */

    padding: 0 3.5rem;
    padding-left: 2rem;
  }
}
@media (max-width: 991px) {
  .bankListContainer {
    /* margin: 0px 25px; */
    padding: 0 1.5rem;
    /* border: 2px solid rgb(255, 0, 0); */
  }
  .tableWraper {
    width: 100%;
  }
  .filterButton {
    display: flex;
  }
  .updateMsg {
    margin-left: 0;
    padding-top: 5px;
    width: 100%;
  }
  .updateMsg0 {
    display: none;
  }
  .tableWraper table {
    /* display: block; */
    /* box-sizing: border-box; */
  }
}

@media (max-width: 768px) {
  .bankListContainer {
    padding: 0 1rem;
  }
  .filterButton {
    /* margin: 10px 5%; */
  }
  .tableWraper {
    width: 90%;
    padding: 0px 0px;
  }
  .tableWraper table {
    display: block;

    font-size: 14px;
    /* font-size: 0.75rem; */

    /* width: 100%; */
    /* box-sizing: border-box; */
  }
  .tableWraper table th {
    font-size: 15px;

    width: 100%;
    /* font-size: 0.75rem; */
  }
  .rank {
    width: 30% !important;
  }
  .rowRank {
  }

  thead tr th {
    padding: 13px;
    padding-left: 20px;
  }
  tbody td {
    padding: 12 px;
    /* padding-left: 30px; */
  }

  /* .bankName {
    width: 18%;
    color: #2d3748;
    padding-left: 0px;
    padding-right: 0;
    border: 2px solid salmon;
  } */
  .interestRate {
    padding-left: 15px;
  }
  .interestRateData {
    padding-left: 15px;
  }
}

@media (max-width: 568px) {
  .tableWraper {
    width: 100%;
    padding: 0px 0px;
  }
  .filterButton {
    margin: 10px 0;
  }
  .card {
    margin-left: -28px;
    margin-right: -35px;
    font-size: 14px;
    /* height: 34px; */
    /* border: 2px solid saddlebrown; */
  }
  .h1 {
    font-size: 14px !important;
    /* border: 2px solid rgb(235, 21, 195); */
    margin-right: 25px !important;
  }
  .bankSearchMobile {
    /* margin-left: 10px;
    margin-bottom: 10px;

    min-width: 285px;
    max-width: 285px; */
  }
  .mobileSearchContainer {
    /* border: 1px solid slateblue; */

    margin-bottom: 0px;
  }
}

.tooltip {
  background-color: black;
  color: white;
}

.slider {
  width: 70%;
}
.bankType {
  /* border: 1px solid black; */
  padding-left: 15px;
  padding-right: 20px;
}

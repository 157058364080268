@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

/* body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #fcfcfc;
} */
.navWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 102;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #dadce0;

  /* border: 2px solid black; */
}

.transparentDiv {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -9999999;
  background-color: rgba(0, 0, 0, 0.4);
}

.container {
  max-width: 1270px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  /* border: 2px solid black; */
}

.navbar {
  font-family: "Poppins", sans-serif;
  height: 60px;
  background-color: rgb(255, 255, 255);

  width: 100%;
  /* max-width: 1420px; */
  /* border: 2px solid black; */
}

.logo {
  cursor: pointer;
}
/* .logo i {
  font-size:80px;
    position:absolute;
    z-index:0;
    text-align:center;
    width:100%;
    left:0;
    top:-10px;
    color:#34495e;
    -webkit-animation:ring 2s ease infinite;
    animation:ring 2s ease infinite;
} */
.logo h3 {
  font-family: "Impact", "Arial Black", sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 0;
}

.logoImg {
  width: 85px;
}

.menuIcon {
  display: none;
}
.navElements {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  /* border: 2px solid rgb(44, 14, 236); */
}

.navElements ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  /* border: 2px solid brown; */
}

.navElements ul li:not(:last-child) {
  margin-right: 40px;
}

.navElements ul a {
  font-size: 16px;
  font-weight: normal;
  color: #2f234f;
  text-decoration: none;
}

.navElements ul Link.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.ActiveNav {
  content: "";
  /* background-color: #574c4c; */
  border-bottom: 1.6px solid black;
}
.navElements ul {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid black;/ */
  /* margin-top: 15px; */
}
.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1800px) {
  .navbar {
    margin: auto;
    padding: auto;
    /* width: 65%; */
    max-width: 1734px;
  }
  .container {
    max-width: 1500px;
    padding: 0 1.5rem;
  }
}
/* @media (max-width: 768px) {
  .navElements ul li:not(:last-child) {
    margin-right: 30px;
  }
} */

@media (max-width: 900px) {
  .menuIcon {
    display: flex;
    cursor: pointer;
    position: absolute;
    left: 0;
    margin-left: 1rem;
  }

  .navElements {
    position: absolute;
    left: 0;
    top: 60px;
    /* background-color: #fef7e5; */
    background-color: #fff;
    /* z-index: 100; */
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition: inline-size 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s,
      visibility 0s cubic-bezier(0.4, 0, 0.2, 1) 0.25s;
  }

  .navElements.active {
    width: 220px;
  }

  .navElements ul {
    display: block;
    flex-direction: column;
    align-items: flex-start;
  }

  .navElements ul li {
    margin-right: unset;
    margin-top: 22px;
    margin-left: 15px;
  }

  .logo {
    margin-left: 2rem;
  }
}

/* General Styles */
.containerContent {
  background-color: #f1f3f4;
}

.mainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px auto;
  max-width: 800px; /* Adjust for larger screens */
  padding: 20px;
  padding-top: 0;
  padding-bottom: 40px;
}

/* Text Container */
.textContainer {
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textContainer h1 {
  font-size: 2em; /* Adjust heading size */
  margin-bottom: 15px;
}

.textContainer p {
  /* margin: 0px 100px; */
  max-width: 550px;
}

/* Contact Form Container */
.container {
  background-color: #fff;
  border-radius: 10px; /* Consistent border-radius */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
  border: var(--devsite-page-border, 0);
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  padding: 40px;
}

.container h2 {
  margin-top: 0;
  margin-bottom: 15px; /* Remove default top margin */
}

/* Form Fields */
.container input,
.container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Consistent box-sizing */
  font-family: inherit; /* Consistent font family */
}

.container textarea {
  height: 150px; /* Adjust textarea height */
}

/* Submit Button */
.container input[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-family: inherit; /* Consistent font family */
}

.container input[type="submit"]:hover {
  background-color: #45a049;
}

/* Success Message (Optional) */
.successMessage {
  /* border: 1px solid black; */
  color: green; /* Green for success */
  font-size: 19px;
  font-weight: bold;
  margin-left: 30px;
  margin-top: 20px;
}
.sendButton {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.errorText {
}

.breadcrumbsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid black; */
  z-index: 50;
  padding: 13px 0px 0;
  /* background-color: #f5f7fa; */
  background-color: transparent;
}

.breadcrumbSubCont {
  width: 100%;
  max-width: 1270px;
  /* border: 2px solid black; */
}

.breadcrumbSubDiv {
  display: flex;
  padding-left: 20px;
  /* width: 100%; */
}

.breadcrumb {
  font-size: 13px;
  color: #5f6368;
  font-weight: 400;
  text-decoration: none;
  margin-right: 5px;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
}
.breadcrumb:hover {
  color: #1a73e8;
}

.breadcrumbActive {
  font-size: 13px;
  color: #5f6368;
  font-weight: 400;

  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
  /* text-decoration: none; */
}
.breadcrumbActive:hover {
  color: #1a73e8;
}

@media (min-width: 1800px) {
  .breadcrumbSubCont {
    width: 100%;
    max-width: 1485px;
    /* border: 2px solid black; */
  }
}
@media (min-width: 1240px) {
  .breadcrumbSubDiv {
    /* width: 1434px; */
  }
}
@media (max-width: 991px) {
  .tableWraper {
    width: 100%;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 568px) {
  .breadcrumbsContainer {
    padding: 5px 0px 0;
  }
  .breadcrumb {
    font-size: 14px;
  }
  .breadcrumbActive {
    font-size: 14px;
  }
}

.messageContainer {
  width: 100%;
  /* border: 2px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card {
  /* font-size: 16px; */
  width: 60%;
  height: 100%;
  background-color: #f5f7fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 10px; */
  justify-content: center;
  padding: 20px 30px;
  gap: 10px;
  position: relative;
  overflow: hidden;
  /* border: 3px solid salmon; */
  /* box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.062); */
}

.cookieSvg {
  width: 50px;
  height: 50px;
  /* border: 5px solid saddlebrown; */
}

.cookieSvg g path {
  fill: rgb(97, 81, 81);
}

.cookieHeading {
  font-size: 1.2em;
  margin: 0;
  font-weight: 800;
  color: rgb(26, 26, 26);
}

.cookieDescription {
  text-align: center;
  font-size: 0.9em;
  font-weight: 600;
  color: rgb(99, 99, 99);
}

.buttonContainer {
  display: flex;
  gap: 20px;
  flex-direction: row;
}

.acceptButton {
  /* height: 30px; */

  background-color: #1976d2;
  color: #fff;
  padding: 5px 10px;
  width: 100%;
  /* font-size: 15px; */
  border-radius: 4px;
  font-weight: 700;
  border: 0;
  /* max-width: 100px; */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  /* border: 1px solid #dadce0; */
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-weight: 500;
  /* font-size: 0.875rem; */
  letter-spacing: 0.0307142857em;
}

.acceptButton:hover {
  background-color: #1967d2;
  transition-duration: 0.2s;
}

@media (max-width: 700px) {
  .card {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .card {
    width: 100%;
  }
}

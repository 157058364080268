/* * {
  padding: 0px;
  margin: 0px;
} */
.heading {
  /* font-family: Inter, sans-serif; */
  /* color: red; */
  /* border: 2px solid black; */
}

.homeContainer {
  /* height: 100%; */
  background-color: #f8f9fa;
  /* border: 2px solid #258d75; */
  min-height: 100%;
  position: relative;
  padding-bottom: 40px;
}

/* .homeContainer .banner img {
  max-width: 80%;
} */

.textWrapper {
  width: 100%;
  padding: 0px 20px;
  text-align: center;

  /* border: 2px solid #258d75; */
}

.searchContianer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 36rem;
}

.homeRow {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* border: 2px solid slateblue; */
}

.textContent h1 {
  /* color: #5665d6; */
  font-size: 2.7rem;
  /* margin: 20px; */
  /* margin-top: 1vh; */
  margin-bottom: 20px;
  /* max-width: 16ch; */
  font-family: Inter, sans-serif;
  letter-spacing: -0.05em;
  font-weight: 700;
  line-height: 1.2;
}

.subHeading {
  color: #1976d2;
}
/* .homeContainer .textContent p {
  margin: 0;
  line-height: 1.6;
  font-size: 15px;
  color: #b3b3b3;
} */
/* .homeContainer .textContent button {
  border: 0;
  border-radius: 20px;
  padding: 12px 30px;
  margin-top: 30px;
  cursor: pointer;
  color: #fff;
  background-color: #6c63ff;
} */

.banner {
  /* border: 2px solid #258d75; */
  display: flex;
  justify-content: right;
}
/* End Landing Page */

.mainImage {
  /* mix-blend-mode:exclusion */
  /* width: 600px;
  height: 400px; */
  /* border: 2px solid #02211a; */
}
@media (min-width: 1800px) {
  .homeRow {
    /* margin-top: 25px; */
    padding-top: 25px;
  }
}

@media (min-width: 991px) {
  .textContent {
    margin-left: 15rem;
  }
}

@media (max-width: 768px) {
  .textContent h1 {
    font-size: 2rem;
    margin-top: 1.5vh;
  }
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homeRow {
    margin-top: 0px;
  }
}

@media (max-width: 568px) {
  .heading {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif !important;
    font-size: 23px !important;
    /* color: red; */
    /* border: 2px solid black; */
  }
  .textContent h1 {
    margin-bottom: 0px;
  }
}

/* FixedDepositPage.module.css */
.content {
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 100px;
}

.container h1 {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.container p {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
}

.container li {
}

/* Style other elements as needed */

/*======================
    404 page
=======================*/
@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Protest+Strike&family=Raleway:ital,wght@0,100;1,100&display=swap");
.page404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page404 img {
  width: 100%;
}

.fourzerofourbg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.fourzerofourbg h1 {
  font-size: 80px;
}

.fourzerofourbg h3 {
  font-size: 80px;
}

.link404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contantbox404 {
  margin-top: -50px;
}

.authorsWrap1 {
  display: flex;
  /* display: none; */
  /* align-items: last baseline; */
  justify-content: center;
  /* border: 1px solid black; */
  /* background-color: #FFFFFF; */
  margin: 45px 0px 24px 0px;
  /* border-top: 1px solid #c9c8cf; */
  /* border: 1px solid #c9c8cf; */
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */

  /* border-bottom: 1px solid #c9c8cf; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
  background-color: #f8f9fa;
  margin-top: 5rem;
  /* height: 88vh; */
  /* position: -webkit-sticky;
  position: sticky;
  top: 60px;
  z-index: 1000; */
  border-radius: 4px;
  padding: 0;
}
.filters1 {
  /* width: 220px; */
  width: 100%;
  padding: 0px 20px;
  border-radius: 4px;
  margin-top: 0px;
  /* border: 1px solid black; */
  /* margin: 1rem 0; */
  /* background: #f4f6f7; */
  /* box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}
.filters1 .listDiv {
  /* margin-bottom: 15px; */
  /* border: 1px solid black; */
  border-top: 1px solid #dadce0;
  padding-top: 10px;
  margin-top: 0;
  list-style: none;
}
.filterHeading1 {
  /* text-align: center; */
  margin-bottom: 8px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: block;
}

.subFilterHeading {
  margin-bottom: 5px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin-bottom: 0;
  /* margin-left: 10px; */
  /* color: #4a5568; */
  display: block;
}
.subTenorDiv {
  margin-left: 16px;
}
.customDivider::before,
.customDivider::after {
  content: "";
}

.customDivider::before,
.customDivider::after {
  position: relative;
  border-top: 1px solid #ccc;
  display: inline-block;
  vertical-align: middle;
  width: 43%;
  /* margin-bottom: 0.15em; */
  overflow: hidden;
}

.customDivider::before {
  right: 0.5em;
  margin-left: 3%;
}

.customDivider::after {
  left: 0.5em;
  /* margin-right: -10%; */
}

.disableDiv {
  color: rgba(60, 64, 67, 0.38);
  padding: 10px;
  border-radius: 4px;
  border: 1.5px solid rgba(60, 64, 67, 0.38);

  /* background-color: #f5f7fa; */

  /* background-color: #fff; */
}
.enableDiv {
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  padding: 10px;
  border-radius: 4px;
  border: 1.5px solid #1677ff;
}
/* .enableDiv{
  background-color: #d3ddf1;
  border: 1.5px solid #1677ff;
  cursor: pointer;
} */
.disableDiv:hover {
  background-color: #d3ddf1;
  border: 1.5px solid #1677ff;
  cursor: pointer;
}
.rangeSlider .MonthAndYear {
  border: none;
  border-radius: 4px;
  padding: 10px;
  /* box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  outline: none;
  /* color: dimgray; */
}
.MonthAndYearDis {
  border: none;
  border-radius: 4px;
  outline: none;
  color: rgba(60, 64, 67, 0.38);
  padding: 10px;
}
.filtersDiv1 {
  margin-left: 21px;
}
.filterLabel1 {
  margin-left: 8px;
}
.checkBox1 {
  /* margin-right: 8px; */
  margin-bottom: -5px;
}
.clear1 {
  color: rgb(45, 146, 241);
  margin: 0;
  padding: 0;
  margin-left: 45px;
  cursor: pointer;
}

.banksCount {
  font-size: 12px;
}

.rangeSlider {
  margin-top: 10px;
  /* border: 2px solid slateblue; */
  padding: 10px;
}

@media (max-width: 991px) {
  .authorsWrap1 {
    display: none;
  }
}

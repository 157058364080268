.bankListContainer4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  margin-top: 2vh;
  padding: 0 2rem;
}
.bankListWrapper {
  width: 56%;
}
/* Container for overall styling */
.bankListContainer4 {
  width: 100%; /* Adjust width as needed */
  border-radius: 1px;
}

.tableWraper4 {
  width: 100%;
  border-top: 1px solid #c9c8cf;
  /* border: 1px solid #c9c8cf; */
  margin-top: 2vh;
}
/* Table styling */
.tableWraper4 table {
  overflow: auto;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  /* border: 1px solid #dadce0; */
}

.responsiveTable4 th,
td {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-family: "Poppins", sans-serif;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.responsiveTable4 th {
  background-color: #f4f6f7;
  font-weight: 700;
  font-family: Inter, sans-serif;
}
.responsiveTable4 td {
  color: #2d3748;
  font-family: Inter, sans-serif;
}

.responsiveTable4 tbody td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 40px;
}

tbody tr:hover {
  background-color: #f4f6f7;
}
.bankName4 {
  width: 38%;
  color: #2d3748;
}
.classColumn4 {
  text-align: center;
}
.sortDiv4 {
  text-align: center;
}
th:hover {
  background-color: #e5e5e5;
}
.responsiveTable4 thead {
  vertical-align: top;
  font-size: 16px;
  border-radius: 4px;
  color: #4a5568;
  /* cursor: pointer; */
  padding-left: 20px;
}

tbody {
  color: #2d3748;
}
.bankName4 a {
  color: #2d3748;
}

.responsiveTable4 thead th {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  padding: 18px;
  padding-left: 40px;
  /* padding-right: 10px; */
  /* border: 1px solid black; */
}

.heading4 {
  margin-top: 40px;
}
.fdRate {
  text-align: center;
}
.bankHeading4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.interestRate4 {
  padding-left: 14px;
}

.card {
  width: 100%;
  max-width: 590px;
  height: 70px;
  height: 80px;
  /* background: #353535; */
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.img {
  width: 60px;
  height: 60px;
  margin-left: 10px;
  border-radius: 10px;
  background: linear-gradient(#ffff, #ffff);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e6e5ee;
}

.textBox {
  width: calc(100% - 50px);
  margin-left: 10px;
  /* color: black; */
  font-family: "Poppins" sans-serif;
}

.textContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 10px; */
  padding: 0;
}

.span {
  font-size: 10px;
}

.h1 {
  font-size: 25px;
  font-weight: bold;
  margin: 0;
}

.p {
  font-size: 14px;
  font-weight: lighter;
}

.features {
  margin-top: 30px;
}
.featuresList {
  margin: 20px 0;
  margin-bottom: 50px;
}
.featuresList ul li {
  margin: 10px 0;
  font-size: 0.95rem;
  line-height: 16px;
  color: #8995a1;
}
.arrow {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}
@media (min-width: 1800px) {
  /* .tableWraper {
        width: 100%;
        border: 2px solid red;
      } */
  .bankListContainer4 {
    margin-top: 4vh;
  }
  .tableWraper4 {
    margin-top: 3vh;
  }
  .bankListWrapper4 {
    margin: auto;
    padding: auto;
    /* width: 65%; */
    max-width: 1734px;
  }
  .bankListWrapper {
    width: 40%;
  }
}
@media (max-width: 991px) {
  .bankListWrapper {
    width: 100%;
  }
  .filterButton4 {
    display: flex;
  }
  .updateMsg4 {
    margin-left: 0;
    padding-top: 5px;
    width: 100%;
  }
  .updateMsg04 {
    display: none;
  }
}

@media (max-width: 768px) {
  .bankListContainer4 {
    padding: 0 1rem;
    margin-top: 2.5rem;
  }
  .bankListWrapper {
    width: 100%;
  }
  .tableWraper4 table {
    display: block;

    font-size: 14px;
    /* font-size: 0.75rem; */

    /* width: 100%; */
    box-sizing: border-box;
  }
  .tableWraper4 table th {
    font-size: 15px;

    width: 100%;
    /* font-size: 0.75rem; */
  }
  .appFavicon4 img {
    object-fit: contain;
    min-width: 0;
    width: 18px;
    height: 18px;
    border-radius: 3px;
  }
  .responsiveTable4 thead tr th {
    padding-left: 20px;
    /* padding-right: 0; */
  }
  .responsiveTable4 tbody td {
    padding-left: 20px;
  }
  .card {
    width: 100%;
  }
  .img {
    margin-left: 0px;
  }
  .textBox {
    /* border: 1px solid blue; */
    /* padding-top: 8px; */
  }
  .textContent {
    /* border: 1px solid rgb(235, 91, 7); */
  }

  .h1 {
    /* border: 1px solid rgb(18, 18, 57); */
    font-size: 22px;
  }
  .p {
    /* border: 2px solid rgb(128, 9, 27); */
    font-size: 12px;
  }

  .featuresList ul li {
    margin: 10px 0;
    font-size: 0.85rem;
    line-height: 16px;
    color: #8995a1;
  }
}

.FDcontainer4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.tooltip4 {
  background-color: black;
  color: white;
}

.slider4 {
  width: 70%;
}

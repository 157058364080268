body {
  margin: 0;
  padding: 0;
  /* position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%; */
}

.app {
  margin: 0;
  padding: 0;
  min-height: calc(100vh - 25px);
  display: flex;
  flex-direction: column;
}

/* .footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
} */

.authorsWrapFD {
  /* display: flex; */
  /* display: none; */
  align-items: last baseline;
  justify-content: auto;
  /* margin: 10px 10px 0px 0px; */
  background-color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: fixed;
  z-index: 105;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  /* border: 5px solid rgb(14, 199, 205); */
}
.filtersFD {
  width: 100%;
  height: 100%;
  /* height: 300px; */
  padding: 0 20px;
  border-radius: 4px;
}
.filtersFD li {
  /* margin-bottom: 5px; */
  list-style: none;
  /* border-top: 2px solid #0551e9; */
}

.filterHeaderConMain {
  /* border: 2px solid blue; */
  /* position: sticky; */
  /* z-index: 115; */
  /* top: 0; */
  /* overflow:hidden; */
}

.filterHeadingFD1 {
  /* text-align: left; */
  padding-top: 10px;
  margin-bottom: 10px;
  /* border: 2px solid blue; */
  font-family: "Google Sans", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 20px;
  /* color: #202124; */
  /* margin: 0px 10px; */
  border-top: 1px solid #dadce0;
}
.filterHeadingFD {
  /* text-align: left; */
  padding-top: 10px;
  /* margin-bottom: 15px; */
  /* border: 2px solid blue; */
  font-family: "Google Sans", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 20px;
  /* color: #202124; */
  /* margin: 0px 10px; */
  border-top: 1px solid #dadce0;
}
.subFilterHeading {
  /* margin: 40px 0px; */
  /* margin-left: 10px; */
  /* margin-top: 20px; */
  /* border: 2px solid blue; */
  font-family: "Google Sans", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  /* line-height: 20px; */
  /* color: #202124; */
}

fieldset {
  /* padding: 0;
  border: 0; */
}
fieldset label {
  /* margin-left: 8px; */
}

.applyFiltersFD {
  background: #1976d2;
  color: #fff;
  margin-top: 20px;
  padding: 8px;
  /* margin-bottom: 20px; */
  width: 100%;
  /* font-size: 15px; */
  border-radius: 4px;
  font-weight: 700;
  border: 0;
}
/* .majorDivFD {
  border-bottom: 1px solid #ccc;
  margin-bottom: 14px;
  padding-bottom: 4px;
} */
.filtersDivFD {
  margin: 5px 21px;
}
.filterLabelFD {
  margin-left: 8px;
}

.AllDivFD {
  display: inline-flex;
  margin: 0;
  padding: 0;
}
.clearFD {
  color: rgb(45, 146, 241);
  margin: 0;
  padding: 0;
  margin-left: 35px;
  cursor: pointer;
}

.filterHeaderSubCon {
  align-items: center;
  display: flex;
  height: 54px;
  justify-content: center;
}

.filterButtonCon {
  left: 15px;
  position: absolute;
}

.filterButton {
  color: #5f6368;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  z-index: 0;
  overflow: visible;
}

.filterH1 {
  font-family: "Google Sans", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #202124;
  margin: 0;
  padding: 0;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
}

.BankType {
  /* border: 2px solid chartreuse; */
  padding: 0px 10px;
  /* padding-top: 0; */
}
.bankTypeRows {
  /* border: 2px solid blue; */
  margin: 5px 15px;
}
.bankTypeRows1 {
  /* border: 2px solid blue; */
  margin: 5px 0px;
}
.customDivider::before,
.customDivider::after {
  content: "";
}

.customDivider::before,
.customDivider::after {
  position: relative;
  border-top: 1px solid #ccc;
  display: inline-block;
  vertical-align: middle;
  width: 43%;
  /* margin-bottom: 0.15em; */
  overflow: hidden;
}

.customDivider::before {
  right: 0.5em;
  margin-left: 3%;
}

.customDivider::after {
  left: 0.5em;
  /* margin-right: -10%; */
}
.RangeTenor .MonthAndYear {
  border: none;
  border-radius: 4px;
  /* box-shadow: 2px 2px 7px 0 rgb(0, 0, 0, 0.2); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  outline: none;
  padding-left: 10px;
  /* color: dimgray; */
}
.MonthAndYearDis {
  border: none;
  border-radius: 4px;
  outline: none;
  color: rgba(60, 64, 67, 0.38);
  background-color: #f8f8f8;
  padding-left: 10px;
}
.RangeTenor {
  /* border: 2px solid rebeccapurple; */
  padding: 0;
  /* margin: 5px; */
  width: 100%;
}

.yearAndMonthWrp {
  display: flex;
  /* border: 2px solid seagreen; */
  /* margin-bottom: -30px; */
}
.rangeTenorYM {
  margin: 10px 10px 0px 5px;
}
.InputRangeSlider {
  /* border: 2px solid seagreen; */
  width: 100%;
  max-width: 350px;
  padding: 0px 5px;
  /* margin-top: 40px; */
}
.slider {
  /* border: 2px solid seagreen; */
  width: 100%;

  /* -webkit-appearance: none;  */
}

.disableDiv {
  color: rgba(60, 64, 67, 0.38);
  padding: 10px;
  border-radius: 4px;
  border: 1.5px solid rgba(60, 64, 67, 0.38);
}
.disableDiv:hover {
  background-color: #d3ddf1;
  border: 1.5px solid #1677ff;
  cursor: pointer;
}
.enableDiv {
  padding: 10px;
  border-radius: 4px;
  border: 1.5px solid #1677ff;
}

/* @media (max-width: 991px) {
    .authorsWrap {
      display: none;
    }
  } */

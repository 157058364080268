.container {
  overflow: auto;
  background-color: #f8f9fa;
  padding-bottom: 40px;
}

.fdPageHeading {
  color: #161616;
}
.homeRow {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
}

.textWrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* border: 2px solid #258d75; */
}

.textContent h1 {
  /* color: #5665d6; */
  font-size: 2.7rem;
  margin: 15px;
  /* margin-top: 20px; */
  /* max-width: 16ch; */
  font-family: Inter, sans-serif;
  letter-spacing: -0.05em;
  font-weight: 700;
  line-height: 1.2;
}

.subHeading {
  color: #1976d2;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30vh;
}

@media (min-width: 1800px) {
  .homeRow {
    /* margin-top: 25px; */
  }
}

@media (min-width: 991px) {
  .textContent {
    margin-left: 15rem;
  }
}

@media (max-width: 768px) {
  /* .textContent {
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .textContent h1 {
    font-size: 30px;
    padding: 0px 20px;
  }
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homeRow {
    margin-top: 0px;
  }
}
@media (max-width: 568px) {
  .heading {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif !important;
    font-size: 23px !important;
    /* color: red; */
    /* border: 2px solid black; */
  }
}

body {
  font-family: "Roboto", Arial, sans-serif;
}
a {
  text-decoration: none;
}
.wrapperSearch {
  width: 100%;
  max-width: 35.25rem;
  /* margin: 1rem auto; */
  margin-bottom: 0rem;
  /* margin-left: 10px; */
  /* border: 1px solid slateblue; */
}

.searchContainer {
  width: 100%;
  /* border: 1px solid slateblue; */
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border: 1px solid rgb(221, 101, 221);
  margin-right: 0px; */
}

.searchQueryInput {
  width: 100%;
  height: 2.7rem;
  background: #ffffff;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 0.5rem 0 1.5rem;
  font-size: 1.2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075); */
  /* border: 1px solid slateblue; */
}
.searchQueryInput {
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
}

.searchQueryInput:hover {
  border-color: rgba(0, 0, 0, 0.87); /* Change to black on hover */
  background-color: #ffffff;
}

.searchQueryInput:focus-within {
  border: 2px solid rgb(34, 125, 216);
  background-color: #ffffff;

  /* border-color: rgb(59 130 246 / 0.5);  */
}

.searchQuerySubmit {
  width: 100%;
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

.searchIcon {
  color: grey;
}

.input {
  font-size: 16px;
  margin-left: 10px;
}

.searchQuerySubmit:hover {
  cursor: pointer;
}

.endContent {
  margin-right: 10px;

  width: 200px;
  /* border: 1px solid black; */
}

.errorMsg {
  color: #f02849;
  font-family: SFProText-Regular, Helvetica, Arial, sans-serif;
  font-size: 15px;

  /* font-family: "Poppins", sans-serif; */
}

.numberInWords {
  font-size: 14px;
  color: #3c4043;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* font-family: Droid Sans; */
  /* border: 1px solid slateblue; */
  /* width: 400px; */
  /* font-family: Arimo; */
  margin-left: 23px;
  /* margin-top: 5px; */
  margin-bottom: 0px;
}
@media (min-width: 991px) {
  .wrapper {
    margin-left: 20px;
  }
}

@media (max-width: 575px) {
  .wrapperSearch {
    width: 85%;
    margin: 0.5rem auto;
    margin-bottom: 1rem;
  }
  .searchQueryInput {
    height: 3rem;
    border-radius: 1.625rem;
    padding: 0 0.5rem 0 1.5rem;
    font-size: 1.2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075); */
  }
}

.footerWrapper {
  /* background-color: #ea2929; */
  /* border: 2px solid black; */
  /* position: absolute;
  left: 0;
  bottom: 0;
  right: 0; */
  z-index: 200;
  margin-top: auto;
}
footer {
  display: block;
  unicode-bidi: isolate;

  /* height: 50px; */
  /* border: 2px solid black; */
}
.footerContent {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* margin-bottom: 10px; */
  border-top: 1px solid #dadce0;
  /* border: 2px solid black; */
}
.footerTextContainer {
  /* margin-top: 10px; */
  text-align: center;
}
.footerLink {
  color: #1a73e8;
  cursor: pointer;
  margin-top: 4px;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
}
.footerText {
  text-align: center;
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
}
.footerWrapper {
  margin-bottom: 0;
}
.socialIcons {
  margin-left: 20px;
  margin-top: 5px;
}

@media (min-width: 1800px) {
  .footerWrapper {
    /* margin: auto;
    margin-top: 60vh;
    padding: auto;
    max-width: 1420px; */
  }
}

@media (max-width: 568px) {
  .socialIcons {
    margin-bottom: 10px;
  }
}

.container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;

}
.container h1{
    /* color: #2c3e50; */
    margin-bottom: 10px;
}
/* .container h2 {

    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
} */
.container p {
    margin-bottom: 15px;
    font-size: 16px;
}
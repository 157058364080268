.container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    margin-top: 30px;
}

.container ol {
  list-style: disc;
}
.container li {
    margin-bottom: 5px;
    margin-left: 10px;
}
.authorsWrap1 {
  display: flex;
  /* display: none; */
  /* align-items: last baseline; */
  justify-content: left;
  /* border: 1px solid black; */
  margin: 0px 15px 24px 0px;
  margin-top: 4rem;
  /* border-top: 1px solid #c9c8cf; */
  /* border-bottom: 1px solid #c9c8cf; */
}
.filters1 {
  /* width: 220px; */
  width: 100%;
  padding: 20px 20px;
  border-radius: 4px;
  /* margin-top: 10px; */
  /* border: 1px solid black; */
  /* margin: 1rem 0; */
  background: #f8f9fa;
  /* box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3); */
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}
.filters1 li {
  margin-bottom: 10px;

  list-style: none;
}
.filterHeading1 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 700;
  color: #4a5568;

  display: block;
}

.filtersDiv1 {
  margin-left: 25px;
  margin-top: 5px;
}
.filterLabel1 {
  margin-left: 8px;
  cursor: pointer;
}

.clear1 {
  color: rgb(45, 146, 241);
  margin: 0;
  padding: 0;
  margin-left: 45px;
  cursor: pointer;
}

.banksCount {
  /* margin-bottom: 15px; */
  /* border: 1px solid black; */
  font-size: 12px;
}
@media (max-width: 991px) {
  .authorsWrap1 {
    display: none;
  }
}
/* Make sure to use the exact class names applied by NextUI */
.customCheckbox {
  /* Hide the default checkbox */
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid rgb(133, 133, 133);
  border-radius: 2px;
  background-color: #fff;
  margin-top: 5px;
  cursor: pointer;
}

.customCheckbox:checked {
  background-color: #007bff; /* Blue color similar to image */
  border-color: #007bff;
}

.customCheckbox:checked::after {
  content: "";
  display: block;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  margin: auto;
}

.radioInput {
  /* Hide the default radio button */
  box-shadow: 0px 0px 0px 2px rgb(133, 133, 133);
  font-size: 2em;
  width: 15px;
  height: 15px;
  /* margin-right: 7px; */
  margin-top: 10px;
  border: 3px solid #fff;
  background-clip: border-box;
  border-radius: 50%;
  appearance: none;
  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.radioInput:checked {
  box-shadow: 0px 0px 0px 2px #007bff;
  background-color: #007bff;
}

.bankSearch {
  min-width: 280px;
  max-width: 280px;
  margin-bottom: 10px;
  margin-top: 25px;
}

.chip {
  font-size: 12px !important;
  color: red !important;
}
